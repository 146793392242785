import {Component, OnInit} from '@angular/core';
import {Plan} from "../../../models/plan.model";
import {SubscriptionService} from "../../../services/subscription.service";

@Component({
  selector: 'app-plan',
  templateUrl: './plan.component.html',
  styleUrl: './plan.component.scss'
})
export class PlansComponent implements OnInit {
    plans: Plan[] = [];

    constructor(private subscriptionService: SubscriptionService) {}

    ngOnInit(): void {
        this.fetchPlans();
    }

    fetchPlans(): void {
        this.subscriptionService.getPlans().subscribe({
            next: (data) => {
                this.plans = data;
                console.log('Plans:', this.plans);
            },
            error: (err) => {
                console.error('Error fetching plans:', err);
            }
        });
    }
}
