<div id="home" class="main-banner banner-style-two banner-bg-two">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="banner-text">
                    <h1>Make Your Business More Smarter</h1>
                    <p>The moment you focus on a goal, your goal becomes a magnet, pulling you with business and your resources toward it.</p>
                    <div class="banner-btn">
                        <a routerLink="/">Get Started</a>
                        <!-- <button class="banner-video" (click)="ngxSmartModalService.getModal('popupOne').open()"><i class="flaticon-play-button"></i> Watch Video</button> -->
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="banner-image-two">
        <img src="assets/img/sass-landing/laptop.png" alt="laptop">
        <img src="assets/img/sass-landing/iphone.png" alt="iphone">
        <img src="assets/img/shape/home-shape-three.png" alt="shape">
    </div>
    <div class="banner-shape">
        <img src="assets/img/shape/home-shape.png" alt="shape">
        <img src="assets/img/shape/home-shape-two.png" alt="shape">
        <img src="assets/img/shape/home-shape-four.png" alt="shape">
        <img src="assets/img/shape/home-shape-three.png" alt="shape">
    </div>
</div>
<div class="video-popup">
    <!-- <ngx-smart-modal #popupOne [identifier]="'popupOne'">
        <iframe src="https://www.youtube.com/embed/WQ119jAN4Xo" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
    </ngx-smart-modal> -->
</div>