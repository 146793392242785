<div class="header"></div>
<div class="privacy-policy">
    <h1>Política de Privacidade</h1>
    <p><strong>Bem-vindo ao Class Map.</strong></p>
    <p>
        Esta Política de Privacidade descreve como a Class Map Serviços em Tecnologia LTDA ("Class Map")
        coleta, usa, armazena e protege as informações fornecidas pelos usuários ao utilizar nosso sistema.
        Estamos comprometidos em garantir a privacidade e segurança de seus dados pessoais, em conformidade
        com a Lei Geral de Proteção de Dados (LGPD).
    </p>

    <h2>1. Coleta de Informações</h2>
    <p>Ao utilizar o Class Map, podemos coletar as seguintes informações:</p>
    <ul>
        <li><strong>Informações pessoais:</strong> Nome, e-mail, telefone, CPF/CNPJ, e outros dados necessários para a
            criação de sua conta e gestão de suas atividades no sistema.
        </li>
        <li><strong>Informações de uso:</strong> Dados sobre o uso do sistema, incluindo horários de acesso, ações
            realizadas e interações com o sistema.
        </li>
        <li><strong>Dados financeiros:</strong> Informações relacionadas ao pagamento, como dados de cartão de crédito
            (processados por plataformas seguras de pagamento).
        </li>
    </ul>

    <h2>2. Uso das Informações</h2>
    <p>As informações coletadas são utilizadas para:</p>
    <ul>
        <li>Gerenciar e operar o sistema Class Map;</li>
        <li>Personalizar a experiência do usuário;</li>
        <li>Realizar cobranças e gerenciar assinaturas;</li>
        <li>Enviar notificações, comunicados ou atualizações sobre o sistema;</li>
        <li>Cumprir obrigações legais e regulatórias.</li>
    </ul>

    <h2>3. Compartilhamento de Dados</h2>
    <p>Os dados pessoais coletados não serão vendidos, alugados ou compartilhados com terceiros, exceto:</p>
    <ul>
        <li>Quando necessário para prestação dos serviços contratados (ex.: plataformas de pagamento);</li>
        <li>Para cumprimento de obrigações legais;</li>
        <li>Com empresas parceiras envolvidas na operação do sistema, sempre em conformidade com esta Política de
            Privacidade.
        </li>
    </ul>

    <h2>4. Armazenamento e Proteção de Dados</h2>
    <p>
        Seus dados são armazenados em servidores seguros e protegidos por medidas técnicas e organizacionais
        apropriadas para evitar acesso não autorizado, perda, alteração ou destruição. Retemos os dados pelo
        período necessário para cumprir as finalidades descritas nesta Política ou conforme exigido por lei.
    </p>

    <h2>5. Direitos dos Titulares</h2>
    <p>Conforme a LGPD, você possui os seguintes direitos sobre seus dados pessoais:</p>
    <ul>
        <li>Confirmar a existência de tratamento de seus dados;</li>
        <li>Solicitar acesso aos seus dados pessoais;</li>
        <li>Corrigir dados incompletos, inexatos ou desatualizados;</li>
        <li>Solicitar a exclusão de dados desnecessários ou tratados em desconformidade com a lei;</li>
        <li>Revogar o consentimento dado para o tratamento de dados.</li>
    </ul>
    <p>Para exercer seus direitos, entre em contato conosco através do e-mail <strong>{{ contactMail }}</strong>.</p>

    <h2>6. Cookies e Tecnologias de Rastreamento</h2>
    <p>
        Utilizamos cookies para melhorar sua experiência no sistema. Você pode configurar seu navegador para
        recusar cookies, mas isso pode afetar a funcionalidade do sistema.
    </p>

    <h2>7. Atualizações da Política</h2>
    <p>
        Reservamo-nos o direito de atualizar esta Política de Privacidade periodicamente. As alterações serão
        comunicadas por meio do sistema ou via e-mail.
    </p>

    <h2>8. Contato</h2>
    <p>Em caso de dúvidas ou solicitações relacionadas à privacidade de seus dados, entre em contato:</p>
    <ul>
        <li><strong>E-mail:</strong> {{ contactMail }}</li>
    </ul>

    <p><strong>Ao utilizar o sistema Class Map, você declara estar ciente e de acordo com os termos desta Política de
        Privacidade.</strong></p>
    <p><strong>Class Map Serviços em Tecnologia LTDA</strong></p>
</div>
