<div id="pricing" class="pricing-section pricing-style-two">
    <div class="container">
        <div class="section-title">
            <h2>Escolha o plano perfeito para sua necessidade</h2>
            <p>Oferecemos planos flexíveis para atender instituições de todos os tamanhos, desde pequenos grupos até grandes turmas. Encontre o plano ideal para gerenciar seus alunos com eficiência e simplicidade.</p>
        </div>
        <div class="pricing-tabs">
            <div class="tabs-container">
                <app-plan></app-plan>
            </div>
        </div>
    </div>
    <div class="price-shape">
        <img src="assets/img/sass-landing/shapes/8.png" alt="shap">
        <img src="assets/img/sass-landing/shapes/9.png" alt="shap">
        <img src="assets/img/sass-landing/shapes/10.png" alt="shap">
        <img src="assets/img/sass-landing/shapes/9.png" alt="shap">
    </div>
</div>


