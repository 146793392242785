<div id="about" class="why-choose why-choose-two pt-100 pb-70">
    <div class="container-fluid">
        <div class="section-title text-center">
            <span>Why Choose Us?</span>
            <h2>We Providing Best Qualities</h2>
            <p>We always provide our best qualities ever. Our best qualities are a clean user interface, subheading, easy to use.</p>
        </div>
        <div class="row align-items-center">
            <div class="col-lg-6">
                <div class="why-choose-img wow fadeInUp" data-wow-duration="1s">
                    <img src="assets/img/sass-landing/laptop.png" alt="why choose image">
                </div>
            </div>
            <div class="col-lg-5">
                <div class="why-choose-text wow fadeInUp" data-wow-duration="1s">
                    <div class="media d-flex">
                        <i class="flaticon-computer mr-3"></i>
                        <div class="media-body">
                            <h3 class="mt-0">Clean User Interface</h3>
                            <p>A good user interface is well-organized, making it easy to locate different tools and options. Euzan provides you with a clean user interface.</p>
                        </div>
                    </div>
                    <div class="media d-flex">
                        <i class="flaticon-layers mr-3"></i>
                        <div class="media-body">
                            <h3 class="mt-0">Easy To Use</h3>
                            <p>It is basically easy to use. And Euzan  describes an action or activity as easy, you mean that it is done in a confident, relaxed way.</p>
                        </div>
                    </div>
                    <div class="media d-flex">
                        <i class="flaticon-countdown mr-3"></i>
                        <div class="media-body">
                            <h3 class="mt-0">Best Performence</h3>
                            <p>Euzan best Performance is the successful ending of a program required by the department, including an education, work, or other program.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="shapes">
            <img src="assets/img/shape/1.png" alt="shape">
            <img src="assets/img/shape/2.png" alt="shape">
            <img src="assets/img/shape/3.png" alt="shape">
            <img src="assets/img/shape/4.png" alt="shape">
            <img src="assets/img/shape/5.png" alt="shape">
            <img src="assets/img/shape/6.png" alt="shape">
            <img src="assets/img/shape/7.png" alt="shape">
        </div>
    </div>
</div>